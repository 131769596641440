/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
window.Vue = Vue
import BootstrapVue from 'bootstrap-vue'
window.BootstrapVue = BootstrapVue

const default_locale = window.default_locale
const format_dt_database = 'YYYY-MM-DD HH:mm:ss'
const format_dt_noseconds_js = 'DD/MM/YYYY HH:mm'
const format_hour = 'HH:mm'
const format_date = 'DD/MM/YYYY'

// ******************* Multiselect
Vue.component('multiselect', require('vue-multiselect').default);
// ******************* Multiselect

// ******************* Mask
import {
    VueMaskDirective
} from 'v-mask'
Vue.directive('mask', VueMaskDirective)
// ******************* Mask

// ******************* VeeValidate
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
    localeChanged
} from "vee-validate";
let lang = require('vee-validate/dist/locale/' + default_locale.locale.substring(0, 2) + '.json')
import * as rules from "vee-validate/dist/rules"

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
        };
    },
    computesRequired: true,
    message: 'The {_field_} is required'
});

/** EXTENDS FOR REQUIRED FIED TO CUSTOMER THE ERROR MESSAGEs */
extend('booking_question_validation', {
    validate(value) {
        if (!value) {
            return { required: true, valid: false };
        } else {
            return {
                required: true,
                valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
            };
        }
    },
    computesRequired: true,
    message: 'This is a mandatory question, please answer!'
});

extend('pc_area_required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
        };
    },
    computesRequired: true,
    message: 'Your Area number is a Pony Club Mandatory requirement'
});

extend('pc_branch_required', {
    validate(value) {
        if (!value) {
            return { required: true, valid: false };
        } else {
            return {
                required: true,
                valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
            };
        }
    },
    computesRequired: true,
    message: 'Your Branch name is a Pony Club Mandatory requirement'
});

extend('pc_centre_required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
        };
    },
    computesRequired: true,
    message: 'Your Centre name is a Pony Club Mandatory requirement'
});

extend('pc_guid_required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1 && value.toString().trim().length >= 1
        };
    },
    computesRequired: true,
    message: 'Your Pony Club user identifier code is a Pony Club Mandatory requirement'
});

extend('xrequired', {
    validate: function (value) {
        console.log("xrequired");
        if (!value) {
            console.log("required2: value is invalid");
            return { required: true, valid: false };
        }

        console.log("required2: value is valid");
        return { required: true, valid: true };
    }, // the validation function
    message: 'BOGUS MESSAGE: This field is required',
    computesRequired: true // this is caveat!
});

extend('booking_question_validation_check', {
    message: 'This is a mandatory question, please answer!',
    computesRequired: true,
    validate: value => {
        return (value == 1 || value == 0 || value == '1' || value == '0')
    }
});

extend('verify_password', {
    message: "Passwords must be a minimum of eight characters long and include one uppercase letter, one lowercase letter and one number.",
    validate: value => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")
        return strongRegex.test(value)
    }
});

extend('percent', {
    message: "The field must be a value between 0.01 and 100.",
    validate: value => {
        var regex = new RegExp("^(100(?:\\.00?)?|\\d?\\d(?:\\.\\d\\d?)?)$")
        if (regex.test(value)) {
            if (value > 0 && value <= 100) return true
        }
    }
});

extend('decimal', {
    message: "The field must be numeric and with a minimum value of 0.1.",
    validate: value => {
        var regex = new RegExp(/^[-\\+]?\s*((\d{1,3}(,\d{3})*)|\d+)(\.\d{0,1})?$/)
        if (regex.test(value)) {
            if (!isNaN(value) && value > 0) return true
        }
    }
});

extend('url', {
    message: "The field must be a valid URL format.",
    validate: value => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(value);
    }
});

extend('computed_higher_date', {
    params: ['currentvalue', 'minvalue', 'labelminvalue'],
    validate(value, {
        currentvalue,
        minvalue,
        labelminvalue
    }) {
        return currentvalue >= minvalue
    },
    message: (fieldName, params) => {
        if (params.minvalue != undefined && params.minvalue.length > 0) {
            let min = moment(params.minvalue, format_dt_database).format(format_dt_noseconds_js)
            return `The ${fieldName} must be higher the ${min}`
        } else {
            return `The ${fieldName} must be higher the ${params.labelminvalue}`
        }
    }
});

extend('computed_minor_date', {
    params: ['currentvalue', 'maxvalue', 'labelmaxvalue'],
    validate(value, {
        currentvalue,
        maxvalue,
        labelmaxvalue
    }) {
        return currentvalue < maxvalue
    },
    message: (fieldName, params) => {
        if (params.maxvalue != undefined && params.maxvalue.length > 0) {
            let max = moment(params.maxvalue, format_dt_database).format(format_dt_noseconds_js)
            return `The ${fieldName} must be minor the ${max}`
        } else {
            return `The ${fieldName} must be minor the ${params.labelmaxvalue}`
        }
    }
});

extend('computed_minor_date_2h', {
    params: ['currentvalue', 'maxvalue', 'labelmaxvalue'],
    validate(value, {
        currentvalue,
        maxvalue,
        labelmaxvalue
    }) {
        let max = moment(maxvalue, format_dt_database).subtract(2, 'h').format(format_dt_database)
        return currentvalue <= max
    },
    message: (fieldName, params) => {
        let datetime = ''
        if (params.maxvalue != undefined && params.maxvalue.length > 0) {
            datetime = moment(params.maxvalue)
        } else {
            datetime = moment(params.labelmaxvalue)
        }
        return `The ${fieldName} must be 2 hours before the start ${datetime.format(format_hour)} on ${datetime.format(format_date)}`
    }
});

extend('between_dates', {
    params: ['currentvalue', 'maxvalue', 'labelmaxvalue', 'minvalue', 'labelminvalue'],
    validate(value, {
        currentvalue,
        maxvalue,
        labelmaxvalue,
        minvalue,
        labelminvalue
    }) {
        return (currentvalue <= maxvalue && currentvalue >= minvalue)
    },
    message: (fieldName, params) => {
        if (params.maxvalue != undefined && params.maxvalue.length > 0 && params.minvalue != undefined && params.minvalue.length > 0) {
            let max = moment(params.maxvalue, format_dt_database).format(format_dt_noseconds_js)
            let min = moment(params.minvalue, format_dt_database).format(format_dt_noseconds_js)
            return `The ${fieldName} must be between ${min} and ${max}`
        } else {
            return `The ${fieldName} must be between ${params.labelminvalue} and ${params.labelmaxvalue}`
        }
    }
});

extend('between_dates_max_2h', {
    params: ['currentvalue', 'maxvalue', 'labelmaxvalue', 'minvalue', 'labelminvalue'],
    validate(value, {
        currentvalue,
        maxvalue,
        labelmaxvalue,
        minvalue,
        labelminvalue
    }) {
        let max = moment(maxvalue, format_dt_database).subtract(2, 'h').format(format_dt_database)
        return (currentvalue <= max && currentvalue >= minvalue)
    },
    message: (fieldName, params) => {
        if (params.maxvalue != undefined && params.maxvalue.length > 0 && params.minvalue != undefined && params.minvalue.length > 0) {
            let max = moment(params.maxvalue, format_dt_database).subtract(2, 'h').format(format_dt_noseconds_js)
            let min = moment(params.minvalue, format_dt_database).format(format_dt_noseconds_js)
            return `The ${fieldName} must be between ${min} and ${max}`
        } else {
            return `The ${fieldName} must be between ${params.labelminvalue} and ${params.labelmaxvalue}`
        }
    }
});

extend('min_three_age', {
    message: "The age must be a value greater than 3 years.",
    validate: value => {
        var min_date = moment().subtract(3, 'y');
        if (min_date.diff(moment(value), 'days') > 0) {
            return true;
        } else {
            return false;
        }
    }
});

extend('is_adult', {
    message: "To create an account you must be 18 years old or older.",
    validate: value => {
        if (moment().diff(moment(value), 'years') >= 18) {
            return true;
        } else {
            return false;
        }
    }
});

extend('equal_values', {
    params: ['currentvalue', 'validatevalue', 'labelvalidate', 'labelcurrent'],
    validate(value, {
        currentvalue,
        validatevalue,
        labelvalidate,
        labelcurrent,
    }) {
        return (currentvalue == validatevalue)
    },
    message: (fieldName, params) => {
        if (params.validatevalue != undefined && params.validatevalue.length > 0) {
            return `The ${params.labelvalidate} is not validate, ${fieldName}`
        } else {
            return `The ${params.labelvalidate} to valid ${params.labelcurrent}`
        }
    }
});

extend('postal_code_en', {
    message: "The field must be in a valid postcode format.",
    validate: value => {
        var pattern = new RegExp('^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$');
        return !!pattern.test(value);
    }
});

localize(default_locale.locale, lang)

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
// ******************* VeeValidate


// Vue Editor Wysiwyg TinyMCE
// es modules
// Vue version
import Editor from '@tinymce/tinymce-vue'

import tinymce from "tinymce/tinymce"
// Theme
import "tinymce/themes/silver"
// Skins
//import 'tinymce/skins/ui/oxide/skin.min.css'
//import 'tinymce/skins/ui/oxide/content.min.css'
//import 'tinymce/skins/content/default/content.min.css'
// Plugin
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'

// NOTE: default needed after require
var EditorMCE = require('@tinymce/tinymce-vue').default

// Vue Flatpickr
import VueFlatPickr from 'vue-flatpickr-component'
VueFlatPickr.watch.value = function value(newValue) {
    this.fp && this.fp.setDate(newValue)
}
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
Vue.use(VueFlatPickr)

// STICKY DIRECTIVE ELEMENTS
// register globally
import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

// Vue crud tables
import VueTables from './vue-crud-tables.js'
Vue.use(VueTables)

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

// Vue Dropzone & cropper
import Dropzone from 'vue2-dropzone'
Vue.component('dropzone', Dropzone)
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import 'cropperjs/dist/cropper.css'

// WTable
import WTable from './components/WTable.vue'
Vue.component("w-table", WTable);

import draggable from 'vuedraggable'
Vue.component('draggable', draggable)

// Profile Media
import ProfileMedia from './components/ProfileMedia.vue'
Vue.component("profile-media", ProfileMedia);

// Image Media
import ImageMedia from './components/ImageMedia.vue'
Vue.component("image-media", ImageMedia);

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Pin security code
// import Sms from './components/security.vue'
// Vue.component('Sms', Sms)

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

// import {StarRating} from 'vue-rate-it'
// Vue.component('star-rating', StarRating)

// import {ImageRating} from 'vue-rate-it'
// Vue.component('image-rating', ImageRating)

// Calendar
// import VCalendar from './components/VCalendar.vue'
// Vue.component("full-calendar", VCalendar);

import { VueReCaptcha } from 'vue-recaptcha-v3'
window.VueReCaptcha = VueReCaptcha

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput)

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)

// Profile Media
import GridCard from './components/GridCard.vue'
Vue.component("grid-card", GridCard);
